import React from 'react';
import { Link } from 'gatsby';
import logo from "../../assets/images/logo.png";

const Navbar = () => {
    return (
        <header
            style={{
                backgroundColor: '#fff',
                padding: '10px 20px',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Link to="/" style={{ display: 'block' }}>
                    <img
                        src={logo}
                        alt="Rutherford Labs Logo"
                        style={{
                            maxHeight: '50px',
                            height: 'auto',
                            width: 'auto',
                        }}
                    />
                </Link>
            </div>
        </header>
    );
};

export default Navbar;
